<template>
  <div class="mx-4">
    <div class="d-flex flex-row">
      <v-select
        v-model="selectedJobId"
        :items="myJobs"
        item-text="name"
        item-value="id"
        label="Choose an active Job"
        outlined
        class="mr-4"
        hide-details
        clearable
      />
      <v-btn class="my-auto" v-if="!newJob" @click="showNewJobForm">
        Create a new Job
      </v-btn>
    </div>

    <div class="pa-4 my-4" v-if="newJob">
      <div>New Job Form</div>
      <v-form ref="form">
        <v-text-field
          :rules="[rules.required]"
          label="Job Name"
          v-model="newJobName"
        />
        <v-textarea label="Job Description" v-model="newJobDescription" />
      </v-form>
      <div class="d-flex justify-space-around">
        <v-btn @click="saveJob"> Save Job </v-btn>
        <v-btn @click="newJob = false"> Cancel </v-btn>
      </div>
    </div>

    <div class="my-6">
      <v-divider />
      <div class="d-flex justify-space-around"> 
          <v-btn v-if="!newJob" color="primary" @click="selectJob"> Select </v-btn>
          <v-btn v-if="!newJob && selectedJobId" @click="deleteJob"> Delete Selected Job </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { rules } from "@/store/util/rules.js";

export default {
    props: {
        value: {
            type: Number,
            required: false
        }
    },
  data: () => ({
    newJob: false,
    myJobs: [],
    selectedJobId: null,
    rules,
  }),
  created() {
    if(this.value){
        this.selectedJobId = this.value;
    }
    this.fetchMyJobs();
  },
  methods: {
    showNewJobForm() {
      this.newJob = true;
    },
    fetchMyJobs() {
      const params = { status: "Active", page: 0, size: 100 };
      this.$axios.get("/job/my-jobs", { params }).then((resp) => {
        this.myJobs = resp.data.content;
      });
    },
    saveJob() {
      if (!this.$refs.form.validate()) {
        this.addMessage("Please check your input");
        this.loading = false;
        return;
      }

      const data = {
        name: this.newJobName,
        description: this.newJobDescription,
      };
      this.$axios.post("/job", data).then((resp) => {
        const newJob = resp.data;
        if (newJob && newJob.id) {
          this.myJobs.push({
            id: newJob.id,
            name: newJob.name,
            description: newJob.description,
          });
          this.selectedJobId = newJob.id;

          this.newJob = false;
          this.newJobName = null;
          this.newJobDescription = null;
        }
      });
    },
    selectJob() {
      const selectedJob = this.selectedJobId ? this.myJobs.find((j) => j.id == this.selectedJobId):null;
      this.$emit("selected", selectedJob);
    },
    deleteJob(){
        const selectedJob = this.myJobs.find((j) => j.id == this.selectedJobId);

        this.$axios.delete("/job/"+ this.selectedJobId).then( () => {
            this.myJobs.splice(this.myJobs.findIndex((j) => j.id == this.selectedJobId), 1);
            this.selectedJobId = null;
            this.$emit("deleted", this.selectJob);
        })
    }
  },
};
</script>

<style>
</style>